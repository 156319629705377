import React from "react";
import { graphql } from "gatsby";
import { getImage, StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";
import Container from "../../components/Container";

import {
  Layout,
  FeatureGridWithOffsetIcons,
  CTAAreYouReady,
  ContractingProcess,
  LinkButton,
  SEO,
  PressSimple,
} from "../../components";

import {
  ComputerDesktopIcon,
  CogIcon,
  ArrowPathIcon,
  BanknotesIcon,
  CheckIcon,
  XMarkIcon,
  ShieldCheckIcon,
  HeartIcon,
  CalculatorIcon,
  DocumentTextIcon,
  GlobeAmericasIcon,
  LockClosedIcon,
  PencilIcon,
  TvIcon,
  LightBulbIcon,
} from "@heroicons/react/24/outline";

const benefits = [
  {
    name: "Servicio Premium Llave en Mano y Post-Venta incluido.",
    description:
      "Nosotros nos encargamos de gestionar todos los trámites, diseño, alianzas con los mejores instaladores para instalación y mantenimiento de tus paneles. Además, contamos con seguro contra daños de hasta $1,000,000 USD.",
    icon: ShieldCheckIcon,
  },
  {
    name: "Monitoreo y Garantía de Producción",
    description:
      "Monitoreamos tu sistema para que siempre esté generando los resultados que mereces. Además, si el sistema no produce lo acordado, te reembolsamos.",
    icon: TvIcon,
  },
  {
    name: "Responsabilidad Ambiental",
    description:
      "Mitiga la generación de CO2 al planeta y haz que tu empresa sea socialmente responsable.",
    icon: LightBulbIcon,
  },
];
const industrialbenefits = [
  {
    title: "Inversión inicial baja del 20%",
    icon: BanknotesIcon,
  },
  {
    title: "Plazos de 1 a 20 años",
    icon: CalculatorIcon,
  },
  {
    title: "Deduce el ISR en un 100% del valor del equipo en el primer año",
    icon: DocumentTextIcon,
  },
];

const comparations = [
  {
    title: "CFE",
    featured: false,
  },
  {
    title: "Suscripción Bright",
    featured: true,
  },
  {
    title: "Prepago Bright",
    featured: false,
  },
];

const features = [
  {
    title: "Cero Inversión",
    tiers: [
      { title: "CFE", value: true },
      { title: "Suscripción", featured: true, value: true },
      { title: "Prepago", value: false },
    ],
  },
  {
    title: "Mantenimiento sin costo",
    tiers: [
      { title: "CFE", value: false },
      { title: "Suscripción", featured: true, value: true },
      { title: "Prepago", value: true },
    ],
  },
  {
    title: "Garantía de producto",
    tiers: [
      { title: "CFE", value: false },
      { title: "Suscripción", featured: true, value: true },
      { title: "Prepago", value: true },
    ],
  },
  {
    title: "Garantía de producción con reembolso",
    tiers: [
      { title: "CFE", value: false },
      { title: "Suscripción", featured: true, value: true },
      { title: "Prepago", value: true },
    ],
  },
  {
    title: "Seguro de instalación",
    tiers: [
      { title: "CFE", value: false },
      { title: "Suscripción", featured: true, value: true },
      { title: "Prepago", value: true },
    ],
  },
  {
    title: "Eres dueño del sistema",
    tiers: [
      { title: "CFE", value: false },
      { title: "Suscripción", featured: true, value: false },
      { title: "Prepago", value: false },
    ],
  },
];

const IndustrialPage = ({ data }) => {
  const brightSteps = getImage(data.brightSteps.childImageSharp);

  return (
    <Layout>
      <SEO
        title="Energía solar sin inversión para tu empresa"
        description="Bright Industria es un servicio premium de energía solar por suscripción y prepago en México."
      />
      {/* Hero Start */}
      <Container className="py-16 bg-white">
        <div className="flex w-full lg:w-1/2 justify-center lg:justify-start items-center px-6 text-center lg:text-left">
          <div>
            <h1 className="text-4xl text-blue-500 font-bold">
              <span className="block font-black">
                Ahorra hasta un 40% en el recibo de luz de tu empresa{" "}
              </span>
              <span className="text-xl block text-green-600 mt-3 font-bold uppercase">
                ¡Con las mejores opciones de financiamiento!
              </span>
            </h1>
            <p className="mt-3 md:mt-5 text-lg md:text-lg text-gray-700">
              En Bright ofrecemos soluciones de energía solar diseñadas
              específicamente para empresas e industrias. Nuestro compromiso es
              ayudarte a reducir costos y promover la sustentabilidad con la
              mejor tecnología disponible.
            </p>
            <div className="flex flex-col mx-auto md:flex-row mt-8 justify-center lg:justify-start space-y-6 md:space-y-0 md:space-x-6">
              <LinkButton shadow arrow size="large" to="/cotizar">
                Solicitar una propuesta
              </LinkButton>
            </div>
          </div>
        </div>
        <div className="flex w-full lg:w-1/2 items-center px-8 mt-8 lg:mt-0 -z-0">
          <div>
            <StaticImage
              alt="Instalación industrial"
              height={450}
              src="../../images/industrial-installation.jpg"
            />
          </div>
        </div>
      </Container>
      {/* Hero End */}
      <FeatureGridWithOffsetIcons
        title="Dedícate a tu negocio, nosotros nos encargamos del resto"
        description="Con Bright, puedes centrarte en lo que realmente importa: tu negocio. Nosotros nos encargamos de gestionar todo lo relacionado con tu sistema de energía solar, además de aliarnos con las mejores cuadrillas de instaladores para la instalación y mantenimiento, para que tú puedas disfrutar de los beneficios sin complicaciones."
        features={benefits}
      />

      <PressSimple background="bg-white" />
      <div className="bg-gray-50 py-16 lg:py-24">
        <div className="flex flex-col mx-auto justify-center lg:justify-between max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl px-8 md:px-0">
          <h2 className="mt-2 text-3xl font-bold text-gray-900 tracking-tight sm:text-4xl  text-center">
            ¡Financia la compra de tu equipo!
          </h2>
          <div className="flex flex-col lg:flex-row justify-center lg:justify-between mt-7 lg:mt-16">
            <div className="flex flex-col mx-auto w-full lg:w-1/2 items-center px-7 text-center lg:text-left max-w-lg self-center">
              <p className="mt-5 md:mt-5 text-base md:text-xl lg:text-lg xl:text-xl text-gray-700 font-bold">
                Compra el sistema fotovoltaico sin descapitalizarte con un un
                Servicio Premium todo incluido.
              </p>
            </div>
            <div className="flex w-full lg:w-1/2 grid grid-cols-1 mt-16 lg:mt-0">
              <ul
                role="list"
                className="space-y-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:space-y-0 lg:gap-x-4"
              >
                {industrialbenefits.map((item, index) => (
                  <li
                    key={index}
                    className={`bg-white px-6 py-5 rounded-lg border border-gray-100 shadow-md ${
                      index === industrialbenefits.length - 1 &&
                      industrialbenefits.length % 2 !== 0
                        ? "sm:col-span-2"
                        : ""
                    }`}
                  >
                    <div className="flex items-center">
                      <div className="inline-flex items-center justify-center p-3 bg-yellow-500 rounded-full">
                        <item.icon className="h-8 w-8 text-white" />
                      </div>
                      <div className="font-medium text-md leading-6 ml-3">
                        <h3>{item.title}</h3>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <ContractingProcess image={brightSteps} />

      <CTAAreYouReady />
    </Layout>
  );
};
export default IndustrialPage;

export const query = graphql`
  query {
    brightSteps: file(
      relativePath: { eq: "illustrations/4_steps_bright.png" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 700)
      }
    }
  }
`;
