import React from "react";
import classnames from "classnames";

type Props = {
  children: React.ReactNode;
  className?: string;
  column?: boolean;
};

const Container = ({ column, className: userClassName, children }: Props) => {
  const className = classnames(
    userClassName,
    !column && "lg:flex-row",
    "flex flex-col mx-auto justify-center lg:justify-between max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl",
  );
  return <div className={className}>{children}</div>;
};

export default Container;
